import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ApmDashboardComponent } from "./apm-dashboard/apm-dashboard.component";
import { LineItemSummaryComponent } from "./apm-dashboard/line-item-summary/line-item-summary.component";
import { GurusStatusComponent } from "./common-dashboard-comp/gurus-status/gurus-status.component";
import { PartsPerformanceComponent } from "./common-dashboard-comp/parts-performance/parts-performance.component";
import { TargetAchievedComponent } from "./common-dashboard-comp/target-achieved/target-achieved.component";
import { DashboardComponent } from "./dashboard.component";
import { GurusInfoListComponent } from "./msr-dashboard/gurus-info-list/gurus-info-list.component";
import { TopTransactingGurusComponent } from "./msr-dashboard/gurus-info-list/top-transacting-gurus/top-transacting-gurus.component";
import { LineItemsComponent } from "./msr-dashboard/line-items/line-items.component";
import { MsrDashboardComponent } from "./msr-dashboard/msr-dashboard.component";
import { NewlyItemsComponent } from "./msr-dashboard/newly-items/newly-items.component";
import { RpmDashboardDetailComponent } from "./rpm-dashboard/rpm-dashboard-detail/rpm-dashboard-detail.component";
import { RpmDashboardComponent } from "./rpm-dashboard/rpm-dashboard.component";
import { RegionPerformanceTrendComponent } from "./super-admin-dashboard/region-performance-trend/region-performance-trend.component";
import { SuperAdminDashboardComponent } from "./super-admin-dashboard/super-admin-dashboard.component";
import { TargetAcheivedComponent } from "./super-admin-dashboard/target-acheived/target-acheived.component";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatTableExporterModule } from "mat-table-exporter";
import { ChartsModule } from "ng2-charts";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppMaterialModule } from "../app-material/app-material.module";
import { AppRoutingModule } from "../app-routing.module";
import { SuperadminModule } from "../superadmin/superadmin.module";
import { RedemptionsComponent } from "./apm-dashboard/redemptions/redemptions.component";
import { CouponSummaryComponent } from './common-dashboard-comp/coupon-summary/coupon-summary.component';
import { PointsTrendComponent } from './common-dashboard-comp/points-trend/points-trend.component';
import { AbsnewPipe } from "../custom-element/pipe/absnew.pipe";
import { DistributorDashboardComponent } from './distributor-dashboard/distributor-dashboard.component';
import { SubDashboardComponent } from './sub-dashboard/sub-dashboard.component';
import { SubDashFilterComponent } from './sub-dash-filter/sub-dash-filter.component'
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { GoogleChartsModule } from 'angular-google-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { KFormatPipe } from './k-format.pipe';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { PaginatorDirective } from "../directives/pagination.directive";
import { SharedModule } from "../shared/shared.module";
// import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';

@NgModule({
  declarations: [
    DashboardComponent,
    LineItemsComponent,
    NewlyItemsComponent,
    GurusInfoListComponent,
    TopTransactingGurusComponent,
    CouponSummaryComponent,
    PartsPerformanceComponent,
    TargetAchievedComponent,
    GurusStatusComponent,
    MsrDashboardComponent,
    TargetAcheivedComponent,
    RegionPerformanceTrendComponent,
    SuperAdminDashboardComponent,
    ApmDashboardComponent,
    LineItemSummaryComponent,
    RpmDashboardComponent,
    RpmDashboardDetailComponent,
    RedemptionsComponent,
    PointsTrendComponent,
    AbsnewPipe,
    DistributorDashboardComponent,
    SubDashboardComponent,
    SubDashFilterComponent,
    KFormatPipe,
    BulkUploadComponent,
   

  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    MatTableExporterModule,
    ChartsModule,
    SuperadminModule,
    NgxSpinnerModule,
    NgxSliderModule,
    // GoogleChartsModule,
    HighchartsChartModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    NgxUiLoaderModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    SharedModule,

    // NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // CanvasJSAngularChartsModule
  ],
})
export class DashboardModule {}
