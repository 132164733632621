import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {HttpClient,HttpParams, HttpErrorResponse} from '@angular/common/http'
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
private DashboardApi=`${environment.apiUrl}api/emandi/portal/dashboard/jobcard_statistics_monthly/V2/`
private doorstepbooking=`${environment.apiUrl}api/emandi/portal/dashboard/doorstep_statistics_monthly/`
private vechical_referal=`${environment.apiUrl}api/emandi/portal/dashboard/referral/`
private jobCard=`${environment.apiUrl}api/emandi/portal/dashboard/Active/Guru/Count/ `
private activeGuruData=`${environment.apiUrl}api/emandi/portal/dashboard/active_guru_data/ `
private lassWise=`${environment.apiUrl}api/emandi/portal/dashboard/Lass_Mech_wise_Data/`
private partsCons=`${environment.apiUrl}api/emandi/portal/dashboard/parts_consumption_data/`
private lob=`${environment.apiUrl}api/emandi/get/lob_s_master_api/`
private dropdown=`${environment.apiUrl}api/emandi/portal/dashboard/dropdown/`
private opty=`${environment.apiUrl}api/emandi/get/single_opty_status/`
private optyMaster=`${environment.apiUrl}api/emandi/get/optypl_master/`
private job=`${environment.apiUrl}api/emandi/portal/reports/job_details_data/`
private updateCard=`${environment.apiUrl}api/emandi/portal/reports/update_jobcard/`
private starGuru=`${environment.apiUrl}api/emandi/update/guru_status/`
private lasStatus=`${environment.apiUrl}api/emandi/portal/super_admin/update_lass/`
private getLass=`${environment.apiUrl}api/emandi/portal/super_admin/get_create_lass/`
private addealer=`${environment.apiUrl}api/emandi/portal/registration/`
private getdealer=`${environment.apiUrl}api/emandi/get/dealer_list/`
private updateclaim=`${environment.apiUrl}api/emandi/update_claim_status/`
private srcreation=`${environment.apiUrl}api/emandi/sc_creation/`
private fetchrate=`${environment.apiUrl}api/emandi/fetch_rate_list/`
private dsv=`${environment.apiUrl}api/emandi/check_dsv_login/`
private pincode=`${environment.apiUrl}api/customer-app/address-by-pincode-v2/`
private servicedealer=`${environment.apiUrl}api/emandi/get/service_division_list/`
private delaerdivision=`${environment.apiUrl}api/emandi/get/division_info/`
private salesdealer=`${environment.apiUrl}api/emandi/portal/super_admin/neev/get_delaer_list/`
private salesdealerlist=`${environment.apiUrl}api/emandi/get_neev_distrcit_dealer_mapping/`
private servicedealerdivision=`${environment.apiUrl}api/emandi/service_dealer_division_info/`
private updateapi=`${environment.apiUrl}api/emandi/account_insert_or_update/`
private userinfo=`${environment.apiUrl}api/emandi/get/user_info/`
private serviceuserinfo=`${environment.apiUrl}api/emandi/service_dealer_user_info/`
private fetchclaim=`${environment.apiUrl}api/emandi/fetch_claim_status/`
private mechcanicupdate=`${environment.apiUrl}api/emandi/update_guru_data/`
private excelUpload=`${environment.apiUrl}api/emandi/bulk/account_create/`
private guruonboardlist=`${environment.apiUrl}api/emandi/get_bulk_account_request_id/`
private dealerexcelupload=`${environment.apiUrl}api/emandi/register_dealer/`
private excelupdate=`${environment.apiUrl}api/emandi/bulk/update_guru/`
private singleDownload=`${environment.apiUrl}api/emandi/get_bulk_account_info/`
token: any;
  constructor(private http:HttpClient) {
    this.token = localStorage.getItem('access_token');
   }
   
  Dashboardapi(Data:any):Observable<any>{
    this.token = localStorage.getItem('access_token');
    return this.http.post(this.DashboardApi,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer ' + this.token}}).pipe(catchError(err=> of(err)))
  }

  doorStepBooking(Data:any):Observable<any>{
    return this.http.post(this.doorstepbooking,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer ' + this.token}}).pipe(catchError(err=> of(err)))
  }
  Vechical_Referal_count(Data:any):Observable<any>{
    return this.http.post(this.vechical_referal,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  activeJobCard(Data:any):Observable<any>{
    return this.http.post(this.jobCard,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  activeGurudata(Data:any):Observable<any>{
    return this.http.post(this.activeGuruData,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  lassWiseData(Data:any):Observable<any>{
    return this.http.post(this.lassWise,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  

  partsConsumptions(Data:any):Observable<any>{
    return this.http.post(this.partsCons,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  lobs(Data:any):Observable<any>{
    return this.http.post(this.lob,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  dropdowndata(Data:any):Observable<any>{
    return this.http.post(this.dropdown,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  optyStatus(Data:any):Observable<any>{
    return this.http.post(this.opty,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  optyplMaster(Data:any):Observable<any>{
    return this.http.post(this.optyMaster,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  jobDetails(Data:any):Observable<any>{
    return this.http.post(this.job,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  updateJob(Data:any):Observable<any>{
    return this.http.post(this.updateCard,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  starGuruUpdate(Data:any):Observable<any>{
    return this.http.post(this.starGuru,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  lasStatusUpdate(Data:any):Observable<any>{
    return this.http.post(this.lasStatus,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  Lass(Data:any):Observable<any>{
    return this.http.post(this.getLass,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  salesDealerList(Data:any):Observable<any>{
    return this.http.post(this.salesdealerlist,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  addDealer(Data:any):Observable<any>{
    return this.http.post(this.addealer,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  getDealer(Data:any):Observable<any>{
    return this.http.post(this.getdealer,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  updateClaim(Data:any):Observable<any>{
    return this.http.post(this.updateclaim,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  srCreation(Data:any):Observable<any>{
    return this.http.post(this.srcreation,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  fetchRate(Data:any):Observable<any>{
    return this.http.post(this.fetchrate,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  fetchClaim(Data:any):Observable<any>{
    return this.http.post(this.fetchclaim,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  dsvLogin(Data:any):Observable<any>{
    return this.http.post(this.dsv,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  pinCode(Data:any):Observable<any>{
    return this.http.post(this.pincode,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  serviceDealer(Data:any):Observable<any>{
    return this.http.post(this.servicedealer,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  dealearDivision(Data:any):Observable<any>{
    return this.http.post(this.delaerdivision,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  salesDealer(Data:any):Observable<any>{
    return this.http.post(this.salesdealer,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  serviceDealerDivision(Data:any):Observable<any>{
    return this.http.post(this.servicedealerdivision,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  updateApi(Data:any):Observable<any>{
    return this.http.post(this.updateapi,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  userInfo(Data:any):Observable<any>{
    return this.http.post(this.userinfo,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  serviceUserInfo(Data:any):Observable<any>{
    return this.http.post(this.serviceuserinfo,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }
  mechanicGuruUpdate(Data:any):Observable<any>{
    return this.http.post(this.mechcanicupdate,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  excelCreate(Data:any):Observable<any>{
    return this.http.post(this.excelUpload,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  guruOnboard(data:any):Observable<any>{
    return this.http.post(this.guruonboardlist,data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  dealerUpload(Data:any):Observable<any>{
    return this.http.post(this.dealerexcelupload,Data,{headers:{"Content-Type":"application/json",'Authorization': 'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
  }

  excelUpdate(Data:any):Observable<any>{
    return this,this.http.post(this.excelupdate,Data,{headers:{"Content-Type":"application/json",'Authorization':'Bearer '+this.token}}).pipe(catchError(err=>of(err)))
}
  async singleDownloadexcel(Data:any):Promise<any>{  
    return  await this.http.post(this.singleDownload,Data,{headers:{"Content-Type":"application/json",'Authorization':'Bearer ' + this.token}}).pipe(catchError(err=>of(err))).toPromise();  
  }


}