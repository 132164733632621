<div class="tb-nav guru-flex guru-justify-space-between">
  <div class="guru-flex ht-100">
    <ul class="tb-nav-menu">
      <li routerLink="/dashboard"  routerLinkActive="active" (click)="navigate()">
        <span class="guru-nav-icon dash-icon"></span>
        <span class="guru-nav-label">Dashboard</span>
      </li>
      <ng-container *ngIf="role === 'RPM' || role === 'SuperAdmin'">
        <li routerLink="/add-user" routerLinkActive="active" (click)="navigate()">
          <span class="guru-nav-icon user-icon"></span>
          <span class="guru-nav-label">Users</span>
        </li>
      </ng-container>
      <ng-container *ngIf="
          role !== 'MSR' &&
          role !== 'APM' &&
          role !== 'Distributor' &&
          role !== 'SuperAdmin'&&
          role !== 'DEALER'
        ">
        <li routerLink="/apm-list" routerLinkActive="active" (click)="navigate()">
          <span class="guru-nav-icon apm-list-icon"></span>
          <span class="guru-nav-label">APM List</span>
        </li>
      </ng-container>
      <ng-container *ngIf="
          role !== 'MSR' && role !== 'Distributor' && role !== 'SuperAdmin'&&
          role !== 'DEALER'
        ">
        <li routerLink="/distributor-list" routerLinkActive="active" (click)="navigate()">
          <span class="guru-nav-icon distributor-list-icon"></span>
          <span class="guru-nav-label">Distributor List</span>
        </li>
      </ng-container>
      <ng-container *ngIf="role !== 'MSR' && role !== 'SuperAdmin' &&
          role !== 'DEALER'">
        <li routerLink="/msr-list" routerLinkActive="active" (click)="navigate()">
          <span class="guru-nav-icon msr-list-icon"></span>
          <span class="guru-nav-label">MSR List</span>
        </li>
      </ng-container>

      <li routerLink="/guru-list" routerLinkActive="active" (click)="navigate()">
        <span class="guru-nav-icon guru-list-icon"></span>
        <span class="guru-nav-label">Guru List</span>
      </li>

      <li [matMenuTriggerFor]="reports" *ngIf="role !== 'SuperAdmin' && role !== 'RPM'&&
          role !== 'DEALER' " routerLinkActive="active"
        [ngClass]="{ active: partsummary.isActive || jobsummary.isActive }">
        <span class="guru-nav-icon reports-icon"></span>
        <div class="guru-flex">
          <span class="guru-nav-label">Reports</span>
          <span class="guru-flex sub-menu-icon"> </span>
        </div>
      </li>
     
      <li routerLink="/training" (click)="navigate()" routerLinkActive="active">
        <span class="guru-nav-icon training-icon"></span>
        <span class="guru-nav-label">Training</span>
      </li>
      <ng-container *ngIf="role === 'MSR' || role === 'Distributor'">
        <li routerLink="/targets" (click)="navigate()" routerLinkActive="active">
          <span class="guru-nav-icon targets-icon"></span>
          <span class="guru-nav-label">Targets</span>
        </li>
      </ng-container>
      <ng-container>
        <li routerLink="/reports" (click)="navigate()" routerLinkActive="active">
          <span class="guru-nav-icon reports-icon"></span>
          <span class="guru-nav-label">Job Summary</span>
          <span class="new-label" style="margin: 10px -6px 0 0;">New</span> 
        </li>
      </ng-container>
     
      <ng-container *ngIf="role === 'RPM' || role === 'SuperAdmin'">
        <li routerLink="/bulk-upload" (click)="navigate();navigate2()" routerLinkActive="active">
          <span class="guru-nav-icon upload-icon"></span>
          <span class="guru-nav-label">Bulk Upload</span>
          <span class="new-label" style="margin: 10px -6px 0 0;">New</span> <!-- New label added -->
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="tb-nav-btn">
    <div class="mr-32">
     
    </div>
    <ng-container *ngIf="role === 'MSR'">
      <button mat-flat-button class="primary-flat-button" (click)="navigate()" routerLink="/add-guru">
        Add Guru
      </button>
    </ng-container>
    
  </div>
</div>

<mat-menu #reports="matMenu">
  <button mat-menu-item [routerLink]="'/report/part-summary'" (click)="navigate()" routerLinkActive="active"
    #partsummary="routerLinkActive">
    <span>Part Summary</span>
  </button>
  <button mat-menu-item [routerLink]="'/report/job-summary'" (click)="navigate()" routerLinkActive="active"
    #jobsummary="routerLinkActive">
    <span>Job Summary</span>
  </button>
 
</mat-menu>
<ng-container *ngIf="!isBulkUploadRoute">
  <div class="super-admin-menus" *ngIf="role === 'SuperAdmin' || role === 'RPM' || role === 'DEALER'">
    <div class="super-admin-menus-item">
      <span>Add:</span>
      <ul>
       
        <li *ngIf="role === 'SuperAdmin'">
          <a routerLink="/add-training" routerLinkActive="active">Training</a>
        </li>
        <li *ngIf="role === 'SuperAdmin'">
          <a routerLink="/add-part-item" routerLinkActive="active"> Part Item</a>
        </li>
        <li *ngIf="role === 'SuperAdmin'">
          <a routerLink="/add-problem-statment" routerLinkActive="active">
            Problem Statement</a>
        </li>
        <li>
          <a routerLink="/reports" routerLinkActive="active">
            Reports</a>
        </li>
        <li  *ngIf="role === 'SuperAdmin' || role === 'RPM'">
          <a routerLink="/leads" routerLinkActive="active">
            Leads</a>
        </li>
  
      
      </ul>
    </div>
    
  </div>
</ng-container>
