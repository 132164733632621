<div class="container-fluid">
    <!-- Tabs -->
    <ul class="nav nav-tabs custom-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'dealer'"
          (click)="setActiveTab('dealer')"
          >Dealer Onboarding</a
        >
      </li>
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'guruonboarding'"
          (click)="setActiveTab('guruonboarding')"
          >Guru Onboarding</a
        >
      </li> -->
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'gurupdation'"
          (click)="setActiveTab('gurupdation')"
          >Guru Updation</a
        >
      </li>
    </ul>
  
    <!-- Content for Dealer -->
    <div class="mt-3" *ngIf="activeTab === 'dealer'">
        <div class="container-fluid">
            <div class="row">
                <div style="display: flex; align-items: center; width: 100%; gap: 10px;">
                    <!-- File Input -->
                    <input
                      type="file"
                      id="fileInput"
                      class="cursor-pointer"
                      (change)="onFileChange($event)"
                      accept=".xlsx, .xls"
                      style="margin-right: 10px;"
                    />
                  
                    <!-- Upload Button -->
                    <button class="btn btn-primary d-flex align-items-center" (click)="uploadexcel()">
                      <i class="fa fa-upload" aria-hidden="true" style="margin-right: 5px;"></i>
                      Upload
                    </button>

                    <button class="btn btn-primary d-flex align-items-center" (click)="resetFileInput('dealer')" >
                        <i class="fa-solid fa-rotate-right" aria-hidden="true" style="margin-right: 5px;"></i>
                        Reset
                      </button>
                      <button class="btn btn-primary d-flex align-items-center" (click)="refreshBtn()" >
                        <i class="fa-solid fa-arrows-rotate" aria-hidden="true" style="margin-right: 5px;"></i>
                        Refresh
                      </button>
                      <a class="btn btn-primary d-flex align-items-center" 
                      href="../../../assets/excel/Dealer_Onboarding.xlsx" 
                      download>
                     <i class="fa-solid fa-circle-down" aria-hidden="true" style="margin-right: 5px;"></i>
                     Download sample template
                   </a>
                  </div>
                  
                  
            </div>
          </div>
    </div>
  
    <!-- Content for Distributor -->
    <!-- <div class="mt-3" *ngIf="activeTab === 'guruonboarding'">
        <div class="container-fluid">
            <div class="row">
                <div style="display: flex; align-items: center; width: 100%;gap: 10px;">
              
                    <input
                      type="file"
                      id="onboardInput"
                      class="cursor-pointer"
                      (change)="onFileChange($event)"
                      accept=".xlsx, .xls"
                      style="margin-right: 10px;"
                    />
                  
                   
                    <button class="btn btn-primary d-flex align-items-center" (click)="uploadexcel()">
                      <i class="fa fa-upload" aria-hidden="true" style="margin-right: 5px;"></i>
                      Upload
                    </button>

                    <button class="btn btn-primary d-flex align-items-center" (click)="resetFileInput('guruonboarding')" >
                        <i class="fa-solid fa-rotate-right" aria-hidden="true" style="margin-right: 5px;"></i>
                        Reset
                      </button>
                      <button class="btn btn-primary d-flex align-items-center" (click)="refreshBtn()" >
                        <i class="fa-solid fa-arrows-rotate" aria-hidden="true" style="margin-right: 5px;"></i>
                        Refresh
                      </button>
                      <a class="btn btn-primary d-flex align-items-center" 
                      href="../../../assets/excel/Guru_onboarding.xlsx" 
                      download>
                     <i class="fa-solid fa-circle-down" aria-hidden="true" style="margin-right: 5px;"></i>
                     Download sample template
                   </a>
                  </div>
                  
                  
            </div>
          </div>
    </div> -->
  
    <!-- Content for Bulk Upload -->
    <div class="mt-3" *ngIf="activeTab === 'gurupdation'">
      <div class="container-fluid">
        <div class="row">
            <div style="display: flex; align-items: center; width: 100%;gap: 10px;">
                <!-- File Input -->
                <input
                  type="file"
                  id="updateInput"
                  class="cursor-pointer"
                  (change)="onFileChange($event)"
                  accept=".xlsx, .xls"
                  style="margin-right: 10px;"
                />
              
                <!-- Upload Button -->
                <button class="btn btn-primary d-flex align-items-center" (click)="uploadexcel()">
                  <i class="fa fa-upload" aria-hidden="true" style="margin-right: 5px;"></i>
                  Upload
                </button>

                <button class="btn btn-primary d-flex align-items-center" (click)="resetFileInput('gurupdation')" >
                    <i class="fa-solid fa-rotate-right" aria-hidden="true" style="margin-right: 5px;"></i>
                    Reset
                  </button>
                  <button class="btn btn-primary d-flex align-items-center" (click)="refreshBtn()" >
                    <i class="fa-solid fa-arrows-rotate" aria-hidden="true" style="margin-right:5px;"></i>
                    Refresh
                  </button>
                  <a class="btn btn-primary d-flex align-items-center" 
                  href="../../../assets/excel/Guru_Updation.xlsx" 
                  download>
                 <i class="fa-solid fa-circle-down" aria-hidden="true" style="margin-right: 5px;"></i>
                 Download sample template
               </a>
              </div>
              
              
        </div>
      </div>
    </div>
  </div>
  



  <div class="mt-5" *ngIf="activeTab === 'dealer'">
    <div class="table-wrapper">
      <div class="guru-list-table" >
      <table
          mat-table
          matTableExporter
          class="full-width-table tb-table"
          matSort
          aria-label="Elements"
          [dataSource]="dataSource"
      >
          
          <ng-container matColumnDef="requestId">
              <th class="firstName-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
                Request ID
              </th>
              <td class="firstName-col" mat-cell *matCellDef="let row">
                  {{row?.request_id}}
              </td>
          </ng-container>
          <ng-container matColumnDef="date">
              <th class="lastName-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
           Date
              </th>
              <td class="lastName-col" mat-cell *matCellDef="let row">
             {{row?.created_date | date: "dd/MM/yyyy h:mm:ss a" : 'Asia/Kolkata'}}
              </td>
          </ng-container>
          <ng-container matColumnDef="status">
              <th class="email-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
                Status
              </th>
              <td class="email-col" mat-cell *matCellDef="let row">
                  {{ row?.status }}
              </td>
          </ng-container>
  
          <ng-container matColumnDef="download">
            <th class="phoneNumber-col" mat-header-cell *matHeaderCellDef style="width: 20%;">Download</th>
            <td class="phoneNumber-col" mat-cell *matCellDef="let row">
              <button class="btn btn-primary d-flex align-items-center" 
                      (click)="downloadReport(row)" 
                      [disabled]="row.status === 'in_progress'">
                <i class="fa-solid fa-circle-down" aria-hidden="true" style="margin-right: 5px;"></i>
                Download
              </button>
            </td>
          </ng-container>
          
        
          <tr
          class="tb-head-row"
          mat-header-row
            *matHeaderRowDef="displayedColumns"
          ></tr>
          <tr
          class="tb-item-row"
          mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
      </table>
      </div>
  
      <!-- <ng-container >
          <div class="no-item-found">No record found</div>
      </ng-container> -->
  
      <mat-paginator
      appPagination
        showFirstLastButtons
        [length]="totalRows"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [hidden]="showNodata"
        (page)="pageChanged($event)"
        class="custom-paginator"
        [hidePageSize]="true"
      >
      </mat-paginator>
  </div>
  </div>
  <!-- <div class="mt-5" *ngIf="activeTab === 'guruonboarding'">
  <div class="table-wrapper">
    <div class="guru-list-table" >
    <table
        mat-table
        matTableExporter
        class="full-width-table tb-table"
        matSort
        aria-label="Elements"
        [dataSource]="dataSource"
    >
        
        <ng-container matColumnDef="requestId">
            <th class="firstName-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
              Request ID
            </th>
            <td class="firstName-col" mat-cell *matCellDef="let row">
                {{row?.request_id}}
            </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th class="lastName-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
         Date
            </th>
            <td class="lastName-col" mat-cell *matCellDef="let row">
           {{row?.created_date | date: "dd/MM/yyyy"}}
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th class="email-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
              Status
            </th>
            <td class="email-col" mat-cell *matCellDef="let row">
                {{ row?.status }}
            </td>
        </ng-container>

        <ng-container matColumnDef="download">
          <th class="phoneNumber-col" mat-header-cell *matHeaderCellDef style="width: 20%;">Download</th>
            <td class="phoneNumber-col" mat-cell *matCellDef="let row">
              <button class="btn btn-primary d-flex align-items-center"[disabled]="row.status === 'in_progress'" (click)="downloadReport(row)" >
                <i class="fa-solid fa-circle-down" aria-hidden="true" style="margin-right: 5px;"></i>
                Download
              </button>
            </td>
        </ng-container>
      
        <tr
        class="tb-head-row"
        mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
        class="tb-item-row"
        mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
    </table>
    </div>

  

    <mat-paginator
    appPagination
      showFirstLastButtons
      [length]="totalRows"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [hidden]="showNodata"
      (page)="pageChanged($event)"
      class="custom-paginator"
      [hidePageSize]="true"
    >
    </mat-paginator>
</div>
</div> -->


<div class="mt-5" *ngIf="activeTab === 'gurupdation'">
  <div class="table-wrapper">
    <div class="guru-list-table" >
    <table
        mat-table
        matTableExporter
        class="full-width-table tb-table"
        matSort
        aria-label="Elements"
        [dataSource]="dataSource"
    >
        
        <ng-container matColumnDef="requestId">
            <th class="firstName-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
              Request ID
            </th>
            <td class="firstName-col" mat-cell *matCellDef="let row">
                {{row?.request_id}}
            </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th class="lastName-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
         Date
            </th>
            <td class="lastName-col" mat-cell *matCellDef="let row">
              {{ row?.created_date | date: "dd/MM/yyyy h:mm:ss a" : 'Asia/Kolkata' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th class="email-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
              Status
            </th>
            <td class="email-col" mat-cell *matCellDef="let row">
                {{ row?.status }}
            </td>
        </ng-container>

        <ng-container matColumnDef="download">
          <th class="phoneNumber-col" mat-header-cell *matHeaderCellDef style="width: 20%;">Download</th>
            <td class="phoneNumber-col" mat-cell *matCellDef="let row">
              <button class="btn btn-primary d-flex align-items-center"[disabled]="row.status === 'in_progress'" (click)="downloadReport(row)" >
                <i class="fa-solid fa-circle-down" aria-hidden="true" style="margin-right: 5px;"></i>
                Download
              </button>
            </td>
        </ng-container>
      

      
        
        
        
        <tr
        class="tb-head-row"
        mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
        class="tb-item-row"
        mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
    </table>
    </div>

    <!-- <ng-container >
        <div class="no-item-found">No record found</div>
    </ng-container> -->

    <mat-paginator
    appPagination
      showFirstLastButtons
      [length]="totalRows"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [hidden]="showNodata"
      (page)="pageChanged($event)"
      class="custom-paginator"
      [hidePageSize]="true"
    >
    </mat-paginator>
</div>
</div>
<ngx-spinner></ngx-spinner>