import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Dashboard } from 'aws-sdk/clients/quicksight';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { UserService } from 'src/app/services/user.service';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from 'ngx-spinner';
import { PageEvent } from '@angular/material/paginator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-dealer',
  templateUrl: './add-dealer.component.html',
  styleUrls: ['./add-dealer.component.scss']
})
export class AddDealerComponent implements OnInit {
  states: any;

  constructor(private fb: FormBuilder, private userService: UserService, private spinner: NgxSpinnerService,
    private dashboard:DashboardService,) { }
  sub: Subscription = new Subscription();
  dealerForm: FormGroup;
  dealerfilterForm: FormGroup;
  isAddDealer: boolean = false;
  showNodata: boolean = false;
  dataSource = new MatTableDataSource<any>();
  submitted: boolean = false;
  submitMessage: string;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  dealer: any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  isOpenDropdown:boolean = false;
  isLoading = false;
  showSearchButton: boolean = true;
  showRefreshButton: boolean = false
  usernameValue: string = "";
  regions = [
    "East",
    "North",
    "South",
    "West"
  ];
  displayedColumns = [
    "username",
    "firstName",
     "lastName", 
    //  "email", 
     "Region",
     "phoneNumber", 
    //  "dateJoined"
    "status"
    ];
  ngOnInit(): void {

    this.dealerForm = this.fb.group({
      is_active: [true],
      is_contractor: [false],
      firstName: [ { value: "", disabled: true }, [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator],],
      lastName: [{ value: "", disabled: true }, [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator]],
      mobileNum: [{ value: "", disabled: true },[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.min(6000000000), Validators.maxLength(10),],],
      username: ["", [Validators.required,  this.noWhitespaceValidator],],
      email: [{ value: "", disabled: true },],
      state: [{ value: "", disabled: true }, Validators.required],
      region: [{ value: "", disabled: true }, Validators.required],
    });

    this.dealerfilterForm = this.fb.group({
      mobileNumber: [""]
    });
    this.optyplMaster() 
   
    this.dealerUserlist();
  }
  
  enableFields() {
    const fields = ['firstName', 'lastName', 'mobileNum', 'email', 'state', 'region'];
    fields.forEach(field => {
      this.dealerForm.get(field)?.enable();
      this.dealerForm.get('username')?.disable();
      this.showSearchButton = false;
      this.showRefreshButton = true;
    });
  }
  areOtherFieldsEnabled(): boolean {
    const fields = ['firstName', 'lastName', 'mobileNum', 'email', 'state', 'region'];
    return fields.every(field => this.dealerForm.get(field)?.enabled && this.dealerForm.get(field)?.valid);
}
  editdsv(){
    const fields = ['firstName', 'lastName', 'mobileNum', 'email', 'state', 'region'];
    fields.forEach(field => {
      this.dealerForm.get(field)?.disable();
      this.dealerForm.get('username')?.enable();
      this.showSearchButton = true;
      this.showRefreshButton = false;
    });
  }
  dsvCheck(){
    const username = this.dealerForm.value.username.trim();
    if (username === "") {
      return; 
    }

    const ListInput: any = {} as any;
    ListInput.postn_type_cd_s="dsvadv",
    ListInput.login_s = this.dealerForm.value.username

    try {
      this.spinner.show();
      this.sub.add(this.dashboard.dsvLogin(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response?.result == true) {
            this.enableFields();
            this.usernameValue = username;
          } else {
            Swal.fire({
              icon: 'error',
              text: 'User is not DS ADVISORS!!',
              timer: 2000, 
              showConfirmButton: false
            });
          }
        },
        error: (error) => {
          this.spinner.hide();
          throw error;
        }
      }));
    } catch (error) {
      this.spinner.hide();
      throw(error);
    }
  }

  addUserFormData() {
    this.submitted = true;
   
    if (this.dealerForm.valid) {
      this.spinner.show();
      let userObj = {
        phone_number: this.dealerForm.value.mobileNum,
        username: this.usernameValue,
        first_name: this.dealerForm.value.firstName,
        last_name: this.dealerForm.value.lastName,
        role_id: 16,
        role_name: "DEALER",
        is_active: true,
        email_id: this.dealerForm.value.email,
        state: this.dealerForm.value.state,
        region: this.dealerForm.value.region,
        is_contractor:false
      };
      this.dashboard.addDealer(userObj).subscribe({
        next: (response: any) => {
          if (response.success==true) {
            this.spinner.hide();
            this.showfinalErrorMsg = "";
            this.showfinalSuccessMsg = response.msg;
            this.showToastermessage = true;
            this.submitted = false;
            this.dealerUserlist();
            this.dealerForm.reset({
              is_contractor: false, // Preserve the value
              is_active: true, // Preserve the value
            });
          }
          else {
            this.spinner.hide();
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.error.msg;
            this.showToastermessage = true;
          }
          setTimeout(() => {
            this.showToastermessage = false;
          }, 4000);
        },
        error: (error) => {
          this.spinner.hide();
          this.showToastermessage = true;
          this.showfinalSuccessMsg = "";
          this.showfinalErrorMsg = error.error.msg;
          setTimeout(() => {
            this.showToastermessage = false;
          }, 4000);
          console.log(error);
        },
        complete: () => { },
      });
    }
  }

  optyplMaster() {
    try {
      let data = {};
      this.sub.add(this.dashboard.optyplMaster(data).subscribe({
        next: (res: any) => {
          if (res && res.success === true) {
            this.states= res.result.state
          }else{} 
        },
        error: (error) => {
          console.log(error);
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }

  dealerUserlist(page: number = 0, pageSize: number = 10,) {
    const ListInput: any = {} as any;
    ListInput.role="dealer",
    ListInput.phone_number = this.dealerfilterForm.value.mobileNumber || "";
    ListInput.offset = page;
    ListInput.limit = pageSize;
    try {
      this.spinner.show();
      this.sub.add(this.dashboard.getDealer(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response?.results) {
            this.dealer = response?.results;
            this.dataSource = this.dealer;
            this.totalRows = response?.total_count; 
            this.showNodata = false;
          } else {
            this.dealer = null;
            this.dataSource = null; 
            this.showNodata = true;
          }
        },
        error: (error) => {
          this.spinner.hide();
          throw error;
        }
      }));
    } catch (error) {
      this.spinner.hide();
      throw(error);
    }
  }

  toggleLasStatus(isChecked: boolean, row: any) {
    const ListInput = {
      "phone_number": row.username,
      "is_active": isChecked
    };
  
    // Temporarily store the previous state in case we need to revert it
    const previousState = row.is_active;
  
    try {
      this.spinner.show();
      this.sub.add(this.dashboard.lasStatusUpdate(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.success==true) {
            row.is_active = isChecked; // Update the state based on the response
            Swal.fire({
              icon: 'success',
              text: 'The Dealer status has been updated.',
              timer: 2000, 
              showConfirmButton: false
            });
          } else {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.error.msg;
            setTimeout(() => {
              this.showToastermessage = false;
            }, 2000);
            // Revert to previous state
            row.is_active = previousState;
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.showToastermessage = true;
          this.showfinalSuccessMsg = "";
          this.showfinalErrorMsg = "Error. Please try again after some time";
          setTimeout(() => {
            this.showToastermessage = false;
          }, 2000);
          // Revert to previous state
          row.is_active = previousState;
        }
      }))
    }
    catch(error){
      this.spinner.hide();
      // Handle any additional errors
      row.is_active = previousState;
      throw(error);
    }
  }

  refresh(){
    this.isOpenDropdown = false;
    this.dealerUserlist();
    this.dealerfilterForm.reset();
  }
  applyFilter() {
    this.isOpenDropdown = false;
    this.currentPage = 0;
    this.dealerUserlist();
  }

  reset(){
    this.dealerfilterForm.reset();
    this.dealerUserlist();
    this.closeDropdown();
  }

  openDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
  }
  closeDropdown(){
    this.isOpenDropdown = false;
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.isLoading = true;
    // Multiply pageIndex by pageSize to get the correct offset
    this.dealerUserlist(this.currentPage * this.pageSize, this.pageSize);
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  removeSpace() {
    const inputElement = document.getElementById("username") as HTMLInputElement;
    inputElement.addEventListener("keypress", (event: KeyboardEvent) => {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    });
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}